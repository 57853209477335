@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'IvyOra Bold Italic';
  src: url('../public/fonts/ivyora/IvyOraDisplay-BoldItalic.ttf');
}

@font-face {
  font-family: 'IvyOraLight';
  src: ('../public/fonts/ivyora/IvyOraDisplay-Light.ttf');
}

@font-face {
  font-family: 'Causten Medium';
  src: url('../public/fonts/Causten-Round/Causten-Medium.otf');
}

@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');


.garantir-vaga {
  @apply font-causten text-2xl p-5 bg-gradient-to-r from-darkblue to-lightblue from-60% border-offwhite hover:border-yellow-500 text-offwhite hover:text-yellow-500 rounded-full mx-auto
}

.bggrdlight {
  @apply bg-gradient-to-b from-offwhite to-beige 
}

.bggrddark {
  @apply bg-gradient-to-b from-darkblue to-lightblue text-white
}